import { quizzesActions } from "@/core/redux/store/quizzes/actions";
import { useDispatch } from "react-redux";

export const LoadingError = () => {
  const dispatch = useDispatch();
  // on click reset filters
  const handleReload = () => {
    dispatch(quizzesActions.fetchList());
  };

  return (
    <div className="text-center">
      <img
        className="mx-auto max-w-72 max-h-72"
        src="/images/generic/no-results.svg"
        alt="Loading error"
        title="Loading error"
      />
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Loading Error</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Try adjusting your search or filter to find what you`re looking for.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <button onClick={handleReload} className="m-2 py-2 px-5 btn-primary">
          Reload quizzes
        </button>
      </div>
    </div>
  );
};
