import { Quiz } from "@/core/redux/store/quizzes/types";
import { ArrowLongRightIcon, ArrowPathIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import React from "react";

type Props = {
  quiz: Quiz;
};

const Action: React.FC<Props> = ({ quiz }) => {
  if (quiz.progress?.status === "in_progress") {
    return (
      <Link href={`/quiz/${quiz.slug}`} className="m-2 py-2 px-5 btn-primary" title={`Continue ${quiz.title} Quiz`}>
        <span className="pr-1 text-sm">Continue</span>
        <ArrowLongRightIcon className="inline w-3 h-3 " />
      </Link>
    );
  } else if (quiz.progress?.status === "completed") {
    return (
      <Link
        href={`/quiz/${quiz.slug}?retake=true`}
        className="m-2 py-2 px-5 btn-primary"
        title={`Retake ${quiz.title} Quiz`}
      >
        <span className="pr-1 text-sm">Retake</span>
        <ArrowPathIcon className="inline w-3 h-3" />
      </Link>
    );
  } else {
    return (
      <Link href={`/quiz/${quiz.slug}`} className="m-2 py-2 px-5 btn-primary" title={`${quiz.title} Quiz`}>
        <span className="pr-1 text-sm">Start</span>
        <ArrowLongRightIcon className="inline w-3 h-3 " />
      </Link>
    );
  }
};

export default Action;
