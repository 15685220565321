import { QUIZZES_FILTERS } from "@/core/constants/filters";
import Filter from "./Filters/Filter";
import { useDispatch, useSelector } from "react-redux";
import { filtersSelectors } from "@/core/redux/store/filters/selectors";
import { filtersReducerSlice } from "@/core/redux/store/filters/slices";
import Toggle from "./Filters/Toggle";

export default function Filters() {
  const dispatch = useDispatch();
  const sorting = useSelector(filtersSelectors.sorting);
  const difficulty = useSelector(filtersSelectors.difficulty);
  const not_taken = useSelector(filtersSelectors.not_taken);
  return (
    <>
      <div className="w-full flex items-center justify-between md:px-6 p-1.5 my-1 md:py-4 md:my-6 ">
        <div className="flex mx-auto md:mx-0">
          <Filter
            section={QUIZZES_FILTERS.sorting}
            extraClassNames="pl-1"
            selected={sorting}
            onChange={(value: any) => {
              dispatch(filtersReducerSlice.actions.setSorting(value));
            }}
          />
          <Filter
            section={QUIZZES_FILTERS.difficulty}
            extraClassNames="pl-1 ml-4"
            direction="left"
            selected={difficulty}
            onChange={(value: any) => {
              dispatch(filtersReducerSlice.actions.setDifficulty(value));
            }}
          />
        </div>
        <Toggle
          section={QUIZZES_FILTERS.not_taken}
          extraClassNames="pl-4 border-none hidden md:block"
          direction="left"
          selected={not_taken}
          onChange={(value: any) => {
            dispatch(filtersReducerSlice.actions.setNotTaken(value));
          }}
        />
      </div>
    </>
  );
}
