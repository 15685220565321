import { siteSelectors } from "@/core/redux/store/site/selectors";
import { useSelector } from "react-redux";

export default function HomeHero() {
  const siteConfig = useSelector(siteSelectors.siteConfig)
  return (
    <div className="home-hero mb-2">
      <div className="items-center relative justify-center md:p-16 px-4 py-3 sm:py-8">
        <svg
          className="absolute inset-0 z-0"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="text-gray-700 opacity-25" fill="none" stroke="currentColor" strokeWidth="100">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
          </g>
        </svg>
        <h1 className="font-extrabold tracking-tight relative text-white text-center text-20 sm:text-32 md:mt-1 z-1 opacity-85">
          {siteConfig.siteContentSummary}
        </h1>
        <p className="mx-auto max-w-3xl text-lg sm:text-xl sm:mt-6 opacity-75 tracking-tight text-center z-1">
          Test your knowledge and challenge yourself with our collection of {siteConfig.siteContentItems} quizzes!
        </p>
      </div>
    </div>
  );
}
