import { Switch } from "@headlessui/react";
import clsx from "clsx";

export default function Toggle({ section, selected, onChange, extraClassNames }: any) {
  const oppositeValue = section.options.find((option: any) => option.value != selected)?.value;

  return (
    <Switch
      title={section.description}
      checked={selected}
      defaultChecked={selected}
      onChange={() => onChange(oppositeValue)}
      as="label"
      className={clsx([
        "relative border p-3 rounded-md inline-block text-left min-w-[8rem] border-gray-300 cursor-pointer",
        extraClassNames,
      ])}
      key={section.name}
    >
      {({ checked }) => {
        return (
          <div className="flex items-center">
            <button
              className={`${
                checked ? "bg-orange-601" : "bg-gray-300"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span
                className={`${
                  checked ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-gray-502 transition`}
              />
            </button>
            <span className="ml-2 text-sm text-gray-600">{section.name}</span>
          </div>
        );
      }}
    </Switch>
  );
}
