import { Quiz } from "@/core/redux/store/quizzes/types";
import Action from "./Quiz/Action";
import Link from "next/link";
import { QuizTag } from "@/components/generic/Quiz/QuizTag";
import { Difficulty } from "./Quiz/Difficulty";
import { useMemo } from "react";

type Props = {
  quiz: Quiz;
};

export const QuizItem: React.FC<Props> = ({ quiz }) => {
  const percentage = useMemo(() => {
    if (quiz.progress?.status == "completed") return 100;
    if (!quiz.progress?.questions_answered || !quiz.progress?.questions_total) return 0;

    try {
      const percent = (quiz.progress.questions_answered / quiz.progress.questions_total) * 100;
      return Math.floor(percent);
    } catch (error) {
      console.error(error);
    }
    return 0;
  }, [quiz.progress]);

  return (
    <div className="flex flex-col rounded-lg border border-gray-200 shadow-md text-start bg-white">
      <div className="p-4 md:p-6 grow">
        <div className="flex items-center justify-between mb-4 flex-0 flex-wrap">
          <div className="relative h-6 w-6">
            <QuizTag quiz={quiz} />
          </div>
          <Difficulty difficulty={quiz.difficulty} />
        </div>
        <Link href={`/quiz/${quiz.slug}`} title={`${quiz.title} Quiz`}>
          <h2 className=" flex-0 mb-2 text-xl font-semibold tracking-tight text-gray-900">{quiz.title}</h2>
        </Link>
        <div className="grow mb-3 text-sm text-gray-600 line-clamp-5">{quiz.description}</div>
      </div>
      <div className="progress grow-0 w-full rounded-full h-0.5 ">
        <div className="progress-bar h-0.5 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
      <div className="flex grow-0 bottom-6 w-full justify-end bg-gray-100 py-1 md:py-2 px-4 rounded-b-lg">
        <Action quiz={quiz} />
      </div>
    </div>
  );
};
export default QuizItem;
