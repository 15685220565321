import { useSelector } from "react-redux";
import Filters from "./HomeQuizzes/Filters";
import QuizItem from "./HomeQuizzes/Quiz";
import { QuizzesListStatus } from "@/core/redux/store/quizzes/types";
import { NoResults } from "./HomeQuizzes/NoResults";
import { LoadingQuizzes } from "./HomeQuizzes/LoadingQuizzes";
import { LoadingError } from "./HomeQuizzes/LoadingError";
import { quizzesSelectors } from "@/core/redux/store/quizzes/selectors";
import { ReloadingQuizzes } from "./HomeQuizzes/ReloadingQuizzes";
import clsx from "clsx";

export default function QuizzesList() {
  const list = useSelector(quizzesSelectors.quizzesList);
  const listStatus = useSelector(quizzesSelectors.quizzesListStatus);
  let content = null;
  if (listStatus == QuizzesListStatus.LOADING) content = <LoadingQuizzes />;
  else if (listStatus == QuizzesListStatus.ERROR) content = <LoadingError />;
  else if (list.length == 0) content = <NoResults />;
  else
    content = (
      <div
        className={clsx("grid sm:gap-6 gap-2 md:grid-cols-2 xl:grid-cols-3 mx-2 sm:mx-0", {
          relative: listStatus == QuizzesListStatus.RELOADING,
        })}
      >
        {listStatus == QuizzesListStatus.RELOADING && <ReloadingQuizzes />}
        {list.map((quiz) => (
          <QuizItem key={quiz.id} quiz={quiz} />
        ))}
      </div>
    );

  return (
    <>
      <Filters />
      {content}
    </>
  );
}
