import { generateRange } from "@/core/utils/array";
export const LoadingQuizzes = () => {
  const items = generateRange(9);
  return (
    <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
      {items.map((i) => (
        <LoadingQuiz key={i} />
      ))}
    </div>
  );
};
export const LoadingQuiz = () => {
  return (
    <div className=" flex flex-col p-6 rounded-lg border border-gray-200 shadow-md text-start bg-white">
      <div className="flex flex-0 flex-wrap justify-between items-start">
        <div className="relative mb-5 h-6 w-6">
          <svg
            className="inline-block mr-2 rounded md:inline mb-1 bg-gray-200 dark:bg-gray-700 "
            width={24}
            height={24}
          />
        </div>
        <div className=" rounded h-5 bg-gray-200 dark:bg-gray-700 w-24 mb-4"></div>
      </div>
      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      <div className="grow mb-3 font-normal text-gray-700">
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
      </div>
      <div className=" flex-0 bottom-6 w-full">
        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-16 inset-x-0 bottom-1 mt-4"></div>
      </div>
    </div>
  );
};
