import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export default function Filter({ section, selected, onChange, extraClassNames, direction = "right" }: any) {
  const selectedValueName = section.options.find((option: any) => option.value === selected)?.label;

  return (
    <Menu>
      {({ open }) => (
        <div
          title={section.description}
          className={clsx([
            "relative inline-block text-left min-w-[8rem]",
            extraClassNames,
            open ? "border-gray-300" : "border-gray-300",
          ])}
          key={section.name}
        >
          <label className="absolute -mt-2.5 ml-1 px-1 text-xs bg-gray-502 text-gray-500">{section.name}</label>
          <Menu.Button
            as="div"
            className="group border rounded-md  cursor-pointer flex justify-between text-sm text-gray-700 hover:text-gray-900 p-3 "
          >
            <p className="flex-grow">{selectedValueName}</p>
            <ChevronDownIcon className="h-5 w-5  text-gray-400 group-hover:text-gray-500 flex-0" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                "absolute block right-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none",
                direction === "left" ? "right-0" : "left-0"
              )}
            >
              <div className="py-1">
                {section.options.map((option: any) => {
                  if (section.type === "single") {
                    // some keys are undefined
                    const key = option.value ?? "undefined";
                    return (
                      <Menu.Item key={key}>
                        {({ active }) => (
                          <button
                            className={clsx(
                              option.value === selected ? "font-medium bg-gray-100 text-gray-900" : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "w-full inline-flex items-left px-4 py-2 text-sm"
                            )}
                            onClick={() => onChange(option.value)}
                          >
                            {option.label}
                          </button>
                        )}
                      </Menu.Item>
                    );
                  }
                  return null;
                })}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
}
