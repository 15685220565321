import { SeoHead } from "@/components/generic/SEO/Head";
import { siteSelectors } from "@/core/redux/store/site/selectors";
import { useSelector } from "react-redux";
export const IndexMeta: React.FC = () => {
  const siteConfig = useSelector(siteSelectors.siteConfig);
  return (
    <SeoHead
      title={siteConfig.siteDescription}
      description={siteConfig.siteContentDescription}
      canonical={`https://${siteConfig.siteDomain}/`}
      openGraph={{
        url: `https://${siteConfig.siteDomain}/`,
        title: siteConfig.siteDescription,
        description: siteConfig.siteContentDescription,
        images: [
          {
            url: `https://${siteConfig.siteDomain}${siteConfig.siteAssetsBase}/logo.png`,
            width: 500,
            height: 500,
            alt: siteConfig.siteName,
            type: 'image/png',
          },
        ],
        siteName: siteConfig.siteName,
      }}
    />
  );
};
