import { filtersActions } from "@/core/redux/store/filters/actions";
import { useDispatch } from "react-redux";

export const NoResults = () => {
  const dispatch = useDispatch();
  // on click reset filters
  const handleResetFilters = () => {
    dispatch(filtersActions.resetFilters());
  };
  return (
    <div className="text-center">
      <img
        className="mx-auto max-w-72 max-h-72"
        src="/images/generic/no-results.svg"
        alt="No results found"
        title="No results found"
      />
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No results found</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Try adjusting your search or filter to find what you`re looking for.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <button onClick={handleResetFilters} className="m-2 py-2 px-5 btn-primary">
          Reset filters
        </button>
      </div>
    </div>
  );
};
