type Props = {
  difficulty?: string;
};
export const Difficulty: React.FC<Props> = ({ difficulty }) => {
  if (difficulty == "beginner") {
    return (
      <span className="text-xs font-semibold px-2.5 py-0.5 rounded-full bg-green-100 text-green-800">Beginner</span>
    );
  } else if (difficulty == "intermediate") {
    return (
      <span className="text-xs font-semibold px-2.5 py-0.5 rounded-full bg-yellow-100 text-yellow-800">
        Intermediate
      </span>
    );
  } else {
    return <span className="text-xs font-semibold px-2.5 py-0.5 rounded-full bg-red-100 text-red-800">Advanced</span>;
  }
};
