export const QUIZZES_FILTERS = {
  sorting: {
    id: "sorting",
    name: "Sorting",
    description: "Sort quizzes",
    type: "single",
    options: [
      { value: "-sort_order", label: "Implicit" },
      { value: "title", label: "Title" },
      { value: "difficulty", label: "Difficulty" },
      { value: "-created_at", label: "Newest" },
      { value: "created_at", label: "Oldest" },
    ],
  },
  difficulty: {
    id: "difficulty",
    name: "Difficulty",
    description: "Filter quizzes by difficulty",
    type: "single",
    options: [
      { value: undefined, label: "All" },
      { value: "beginner", label: "Beginner" },
      { value: "intermediate", label: "Intermediate" },
      { value: "advanced", label: "Advanced" },
    ],
  },
  not_taken: {
    id: "not_taken",
    name: "Hide completed",
    description: "Hide quizzes that you have already taken",
    type: "toggle",
    options: [
      { value: false, label: "Show All" },
      { value: true, label: "Hide Completed" },
    ],
  },
};
