import Layout from "@/components/layout/Layout";
import HomeHero from "@/components/pages/index/HomeHero";
import HomeQuizzes from "@/components/pages/index/HomeQuizzes";
import { NextPage } from "next";
import { IndexMeta } from "@/components/pages/index/Meta";

const IndexPage: NextPage = () => {
  return (
    <>
      <IndexMeta />
      <Layout>
        <HomeHero />
        <HomeQuizzes />
      </Layout>
    </>
  );
};

export default IndexPage;
